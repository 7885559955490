import {Component, HostBinding, inject, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {SnackBarType} from './snack-bar-type.enum';

@Component({
	selector: 'aga-snackbar',
	templateUrl: './snackbar.component.html',
	styleUrls: ['./snackbar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent implements OnInit {
	@HostBinding('class') snackBarType: SnackBarType;
	snackBarRef = inject(MatSnackBarRef);

	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { text: string, allowDismiss: boolean, snackBarType: SnackBarType }) {
	}

	ngOnInit(): void {
		this.snackBarType = this.data.snackBarType;
	}
}
