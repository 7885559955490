<div class="aga-custom-alert" role="alert">
	<img *ngIf="data.allowDismiss"
	     id="dismiss"
	     class="dismiss-toast-button"
	     alt="dismiss toast"
	     src="../../../assets/icons/svg/ic_Close_24x24.svg"
	     (click)="snackBarRef.dismissWithAction()">
	<div class="d-flex align-items-center w-100">
		<div class="me-2 col-2 info-icon">
			<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
			     xmlns:xlink="http://www.w3.org/1999/xlink">
				<title>ic_Warning-Info_Light_24x24</title>
				<g id="ic_Warning-Info_Light_24x24" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<path d="M12,2 C14.6710938,2 17.1823047,3.04019531 19.0710937,4.92890625 C20.9598047,6.81769531 22,9.32890625 22,12 C22,13.9738281 21.4141016,15.8935156 20.3057031,17.5514062 C20.0658984,17.9101172 19.5807422,18.0064063 19.2220313,17.7666406 C18.8633203,17.5268359 18.7669531,17.0416406 19.0067578,16.6829688 C19.9427734,15.2829687 20.4375,13.6636328 20.4375,12 C20.4375,7.34753906 16.6524609,3.5625 12,3.5625 C7.34753906,3.5625 3.5625,7.34753906 3.5625,12 C3.5625,16.6524609 7.34753906,20.4375 12,20.4375 C13.5432031,20.4375 15.0525,20.0173438 16.3647266,19.2224219 C16.73375,18.9988672 17.2141406,19.1167969 17.4376953,19.4858594 C17.66125,19.8548828 17.5433203,20.3352734 17.1742578,20.5588672 C15.6178906,21.5016406 13.8286328,22 12,22 C9.32890625,22 6.81769531,20.9598047 4.92890625,19.0710937 C3.04019531,17.1823047 2,14.6710938 2,12 C2,9.32890625 3.04019531,6.81769531 4.92890625,4.92890625 C6.81769531,3.04019531 9.32890625,2 12,2 Z M12,9.3046875 C12.4314844,9.3046875 12.78125,9.65445312 12.78125,10.0859375 L12.78125,17.0390625 C12.78125,17.4705469 12.4314844,17.8203125 12,17.8203125 C11.5685156,17.8203125 11.21875,17.4705469 11.21875,17.0390625 L11.21875,10.0859375 C11.21875,9.65445312 11.5685156,9.3046875 12,9.3046875 Z M12,6.1796875 C12.4314725,6.1796875 12.78125,6.52946504 12.78125,6.9609375 C12.78125,7.39240996 12.4314725,7.7421875 12,7.7421875 C11.5685275,7.7421875 11.21875,7.39240996 11.21875,6.9609375 C11.21875,6.52946504 11.5685275,6.1796875 12,6.1796875 Z"
					      id="Combined-Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
				</g>
			</svg>
		</div>
		<p class="col-10 mb-0 align-self-auto word-break-word alert-message" [innerHTML]="data.text"></p>
	</div>
</div>
